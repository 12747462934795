import TagManager from 'react-gtm-module';
import {useTranslation} from 'react-i18next';
import {useQuery, UseQueryResult, UseQueryOptions} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

// import {getBraze} from '@src/new-structure/useCases/braze';

import {User} from '@santa-web/gen/open-api/service';
import santaAuthorizedOpenapiServicesAtom from '@app/atoms/core/santa-authorized-openapi-services';
import config from '@app/config';
import {convertInsideLanguageToIsoLanguage} from '@app/i18n/utils';
import {getUserQueryKey} from '@app/queryKeys';

const useUser = (options?: UseQueryOptions<User | undefined, unknown, User>): UseQueryResult<User> => {
  const {UserService} = useAtomValue(santaAuthorizedOpenapiServicesAtom);
  const userKey = getUserQueryKey();
  const {i18n} = useTranslation();

  return useQuery<User | undefined, unknown, User>(
    userKey,
    async () => {
      const {user} = await UserService.getMe({});
      return user;
    },
    {
      onSuccess: user => {
        const isLoggedInUser = user.registrationType !== 'ANONYMOUS';
        TagManager.dataLayer({
          dataLayer: {
            isLoggedIn: isLoggedInUser ? 1 : 0,
          },
        });
        if (isLoggedInUser) {
          if (user.language) {
            const language = convertInsideLanguageToIsoLanguage(user.language);
            if (config.env === 'production') {
              i18n.changeLanguage(language);
            }
          }

          // TODO: PC Web은 아직까지는 braze를 사용하지 않아 주석처리함
          // const authId = user?.authId;
          // if (authId) {
          //   getBraze().then(braze => braze.changeUser(authId));
          // }
        }
      },
      ...options,
    }
  );
};

export default useUser;
