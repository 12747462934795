import {NextRouter, useRouter} from 'next/router';
import React from 'react';
import {ParsedUrlQuery} from 'querystring';

import {B2bFlowReferrer, PaymentFlowReferrer} from '@app/pages/order/types';
import {getQueryValue} from '@app/utils/router';

export enum StateType {
  NORMAL = 'n',
  B2B = 'b',
  ONBOARDING = 'o',
  REDIRECTION = 'r',
}

export enum QueryKey {
  STATE = 's',
  DID_DIAGNOSIS_BEFORE_SIGNUP = 'd',
  BOARD_CODE = 'b',
  PRODUCT_ID = 'p',
  REFERRER = 'referrer',
  REDIRECT_TO = 'redirectTo',
}

export interface ParsedUrlStateQuery extends ParsedUrlQuery {
  [QueryKey.STATE]?: string;
}

export abstract class QueryState<Props = unknown, Query extends ParsedUrlStateQuery = ParsedUrlStateQuery> {
  props?: Props = undefined;
  constructor(props?: Props) {
    if (props) {
      this.props = {
        ...props,
      };
    }
  }

  abstract toQuery(): Query;
}

export interface NormalStateProps {
  referrer?: PaymentFlowReferrer;
}
export interface NormalStateQuery extends ParsedUrlQuery {
  [QueryKey.REFERRER]?: PaymentFlowReferrer;
}
export class NormalState extends QueryState<NormalStateProps, NormalStateQuery> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static fromQuery(query: ParsedUrlQuery): NormalState {
    const referrerQueryValue = getQueryValue(query, QueryKey.REFERRER);
    const referrer = (() => {
      switch (referrerQueryValue) {
        case 'm': {
          return referrerQueryValue;
        }
      }
      return undefined;
    })();
    return new NormalState({
      referrer,
    });
  }

  setReferrer(referrer: PaymentFlowReferrer | undefined): NormalState {
    return new NormalState({
      ...this.props,
      referrer,
    });
  }

  toQuery(): NormalStateQuery {
    return {
      ...this.props,
    };
  }
}

export type OnboardingStateProps = {
  didDiagnosisBeforeSignup?: boolean;
};
export enum DidDiagnosisBeforeSignup {
  Y = '1',
  N = '0',
}

export interface OnboardingStateQuery extends ParsedUrlQuery {
  [QueryKey.STATE]: StateType.ONBOARDING;
  [QueryKey.DID_DIAGNOSIS_BEFORE_SIGNUP]?: DidDiagnosisBeforeSignup;
}
export class OnboardingState extends QueryState<OnboardingStateProps, OnboardingStateQuery> {
  static fromQuery(query: ParsedUrlQuery): OnboardingState {
    const didDiagnosisBeforeSignupQueryValue = getQueryValue(query, QueryKey.DID_DIAGNOSIS_BEFORE_SIGNUP);
    const didDiagnosisBeforeSignup = (() => {
      switch (didDiagnosisBeforeSignupQueryValue) {
        case DidDiagnosisBeforeSignup.Y:
          return true;
        case DidDiagnosisBeforeSignup.N:
          return false;
        default:
          return undefined;
      }
    })();
    return new OnboardingState({
      didDiagnosisBeforeSignup,
    });
  }

  setDidDiagnosisBeforeSignup(value?: boolean) {
    return new OnboardingState({
      ...this.props,
      didDiagnosisBeforeSignup: value,
    });
  }

  private getDidDiagnosisBeforeSignupQueryValue(): DidDiagnosisBeforeSignup | undefined {
    if (this.props?.didDiagnosisBeforeSignup === true) {
      return DidDiagnosisBeforeSignup.Y;
    } else if (this.props?.didDiagnosisBeforeSignup === false) {
      return DidDiagnosisBeforeSignup.N;
    }
    return undefined;
  }

  toQuery() {
    const query: OnboardingStateQuery = {
      [QueryKey.STATE]: StateType.ONBOARDING,
    };
    const didDiagnosisBeforeSignupQueryValue = this.getDidDiagnosisBeforeSignupQueryValue();
    if (didDiagnosisBeforeSignupQueryValue != null) {
      query[QueryKey.DID_DIAGNOSIS_BEFORE_SIGNUP] = didDiagnosisBeforeSignupQueryValue;
    }
    return query;
  }
}

export type B2bStateProps = {
  boardCode?: string;
  productId?: string;
};

export interface B2bStateQuery extends ParsedUrlQuery {
  [QueryKey.STATE]: StateType.B2B;
  [QueryKey.BOARD_CODE]?: string;
  [QueryKey.PRODUCT_ID]?: string;
}
export class B2bState extends QueryState<B2bStateProps, B2bStateQuery> {
  static fromQuery(query: ParsedUrlQuery): B2bState {
    const boardCode = getQueryValue(query, QueryKey.BOARD_CODE);
    const productId = getQueryValue(query, QueryKey.PRODUCT_ID);
    return new B2bState({
      boardCode,
      productId,
    });
  }

  setBoardCode(boardCode?: string): B2bState {
    return new B2bState({
      ...this.props,
      boardCode,
    });
  }
  setProductId(productId?: string): B2bState {
    return new B2bState({
      ...this.props,
      productId,
    });
  }

  toQuery(): B2bStateQuery {
    const query: B2bStateQuery = {
      [QueryKey.STATE]: StateType.B2B,
    };

    if (this.props?.boardCode) {
      query[QueryKey.BOARD_CODE] = this.props?.boardCode;
    }
    if (this.props?.productId) {
      query[QueryKey.PRODUCT_ID] = this.props?.productId;
    }
    return query;
  }
}

export type RedirectionStateProps = {
  redirectTo?: string;
};

export interface RedirectionStateQuery extends ParsedUrlQuery {
  [QueryKey.STATE]: StateType.REDIRECTION;
  [QueryKey.REDIRECT_TO]?: string;
}

export class RedirectionState extends QueryState<RedirectionStateProps, RedirectionStateQuery> {
  static fromQuery(query: ParsedUrlQuery): RedirectionState {
    const redirectTo = getQueryValue(query, QueryKey.REDIRECT_TO);
    return new RedirectionState({
      redirectTo,
    });
  }

  toQuery(): RedirectionStateQuery {
    const query: RedirectionStateQuery = {
      [QueryKey.STATE]: StateType.REDIRECTION,
    };

    if (this.props?.redirectTo) {
      query[QueryKey.REDIRECT_TO] = this.props.redirectTo;
    }

    return query;
  }
}

export const isNormalState = (state: QueryState<any, any>): state is NormalState => {
  return state instanceof NormalState;
};

export const isOnboardingState = (state: QueryState<any, any>): state is OnboardingState => {
  return state instanceof OnboardingState;
};

export const isB2bState = (state: QueryState<any, any>): state is B2bState => {
  return state instanceof B2bState;
};

export const isRedirectionState = (state: QueryState<any, any>): state is RedirectionState => {
  return state instanceof RedirectionState;
};

export const useQueryState = (): QueryState => {
  const router = useRouter();
  return React.useMemo(() => {
    const query = router.query;
    const stateString = getQueryValue(query, QueryKey.STATE);
    switch (stateString) {
      case StateType.ONBOARDING:
        return OnboardingState.fromQuery(query);
      case StateType.B2B:
        return B2bState.fromQuery(query);
      case StateType.REDIRECTION:
        return RedirectionState.fromQuery(query);
      default:
        return NormalState.fromQuery(query);
    }
  }, [router]);
};

export const goToB2bProductDetail = async (
  router: NextRouter,
  b2bState: B2bState,
  referrer: B2bFlowReferrer
): Promise<boolean> => {
  return await router.push({
    pathname: '/product/detail',
    query: {
      boardCode: b2bState.props?.boardCode,
      productId: b2bState.props?.productId,
      referrer,
    },
  });
};

export const redirect = async (router: NextRouter, redirectionState: RedirectionState): Promise<boolean> => {
  const redirectTo = redirectionState.toQuery().redirectTo ?? '/';
  return await router.replace(redirectTo);
};
