import {useRouter} from 'next/router';
import React from 'react';
import {useAsync} from 'react-use';
import {useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import {UserProfile} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import * as UserFacade from '@app/facade/user';
import useUser from '@app/hooks/user/useUser';
import useUserProfile from '@app/hooks/user/useUserProfile';
import {EventStorageProvider} from '@app/misc/event-storage';
import {useQueryState} from '@app/misc/query-state';
import {getUserProfileQueryKey} from '@app/queryKeys';

// FIXME: https://riiid-pioneer.atlassian.net/browse/TN-2600
const NonAnonymousOnly = ({children}: {children?: React.ReactNode}): React.ReactElement | null => {
  const isRedirectedRef = React.useRef(false);

  const router = useRouter();
  const queryState = useQueryState();
  const {ProfileService} = useAtomValue(santaOpenapiServicesAtom);
  const queryClient = useQueryClient();
  const {data: user, isFetching: isUserFetching, isSuccess: isUserSuccess} = useUser({suspense: true});
  const {
    data: userProfile,
    isFetching: isProfileFetching,
    isSuccess: isProfileSuccess,
  } = useUserProfile({suspense: true});
  const isAnonymousUser = UserFacade.getIsUserAnonymous(user);
  const isUserDoneOnboarding = UserFacade.isUserOnboardingDone(userProfile);
  const isSuccess = isUserSuccess && isProfileSuccess;
  const isFetching = isUserFetching || isProfileFetching;
  const isAuthenticated = isSuccess && !isAnonymousUser;
  const isNotAuthenticated = !isAuthenticated;
  const isNotUserDoneOnboarding = !isUserDoneOnboarding;
  const isPathnameOnboarding = router.pathname === '/onboarding';

  const shouldGoToOnboardingIntroPage = isNotAuthenticated;
  const shouldGoToOnboardingPage = isNotUserDoneOnboarding && !isPathnameOnboarding;
  const shouldGoToIndexPage = isUserDoneOnboarding && isPathnameOnboarding;

  useAsync(async () => {
    if (isFetching || isRedirectedRef.current) {
      return;
    }

    if (shouldGoToOnboardingIntroPage) {
      await router.replace({pathname: '/onboarding/intro', query: queryState.toQuery()});
    } else if (shouldGoToOnboardingPage) {
      if (userProfile == null) {
        // NOTE: 회원가입시 프로필이 정상 생성되지 않은 경우를 고려한 방어 로직입니다.
        // 추후 PC 웹에서 토익 외의 도메인을 지원하게 되면 삭제 및 플로우 리팩토링 필요
        const {profile} = await ProfileService.createMyProfile({});
        queryClient.setQueryData<UserProfile>(getUserProfileQueryKey(), profile);
      }
      await router.replace({pathname: '/onboarding', query: queryState.toQuery()});
    } else if (shouldGoToIndexPage) {
      await router.replace({pathname: '/', query: queryState.toQuery()});
    }

    isRedirectedRef.current = true;
  }, [router, isFetching, shouldGoToIndexPage, shouldGoToOnboardingPage, shouldGoToOnboardingIntroPage]);

  return shouldGoToIndexPage || shouldGoToOnboardingIntroPage || shouldGoToOnboardingPage ? null : <>{children}</>;
};

const PrivatePage = ({children}: {children?: React.ReactNode}) => {
  return (
    <EventStorageProvider>
      <NonAnonymousOnly>{children}</NonAnonymousOnly>
    </EventStorageProvider>
  );
};

export default PrivatePage;
