import formatDate from 'date-fns/format';

import {User, Language, UserProfile} from '@santa-web/gen/open-api/service';
import {Nullishable} from '@app/utils/type';

export const isUserOnboardingDone = (profile: Nullishable<UserProfile>) => {
  return profile?.onboardingInfo.status === 'DONE';
};

export const getIsUserDiagnosisDone = (profile: Nullishable<UserProfile>) => {
  return profile?.diagnosisInfo.status === 'DONE';
};

export const getIsUserNormal = (user: Nullishable<User>) => {
  return user?.registrationType !== 'ANONYMOUS';
};

export const getIsUserAnonymous = (user: Nullishable<User>) => {
  return user?.registrationType === 'ANONYMOUS';
};

export const getNickname = (user: Nullishable<User>) => user?.personalInfo?.name;
export const getEmail = (user: Nullishable<User>) => user?.personalInfo?.email;

export const getTargetScore = (profile: Nullishable<UserProfile>) => {
  return profile?.onboardingInfo.targetScore;
};

export const getCurrentEstimatedScore = (profile: Nullishable<UserProfile>) => {
  return profile?.currentEstimatedScore;
};

export const getFormattedExaminationDate = (profile: Nullishable<UserProfile>): string | undefined => {
  return profile?.targetExamDate && formatDate(profile.targetExamDate, 'yyyy-MM-dd');
};

export const getProviderName = (user: Nullishable<User>) => user?.registrationType;
export const getId = (user: Nullishable<User>) => user?.id;
export const getAccountInfo = (user: Nullishable<User>) => {
  const providerName = getProviderName(user);
  const userId = getId(user);
  if (!providerName || !userId) return undefined;
  return `${providerName}-${userId}`;
};

const purchasableLanguages: Language[] = ['KO', 'JA', 'EN', 'VI', 'ZH', 'TH'];

export const isPurchasable = (user: Nullishable<User>): boolean => {
  return user?.language != null && purchasableLanguages.includes(user.language);
};

const couponUsableLanguages: Language[] = ['KO', 'JA', 'EN', 'VI', 'ZH', 'TH'];

export const canUseCouponBox = (user: Nullishable<User>): boolean => {
  return user?.language != null && couponUsableLanguages.includes(user.language);
};
