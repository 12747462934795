import produce from 'immer';

import {atomWithDefault} from 'jotai/utils';
import {Configuration, ConfigurationParameters} from '@santa-web/gen/open-api/service';
import {OpenAPIServices, createOpenAPIServices} from '@app/api/openapi';
import learningDomainAtom from '@app/atoms/core/learning-domain';
import {santaAuthorizedOpenapiServicesConfParamsAtom} from '@app/atoms/core/santa-authorized-openapi-services';

/**
 * @description santaAuthorizedOpenapiServicesConfigurationProperties에 더해
 * learning-domain atom을 이용하여 riiid-learning-domain 헤더를 주입해주는 로직이 포함됨.
 */
export const santaOpenapiServicesConfParamsAtom = atomWithDefault<Promise<ConfigurationParameters>>(async get => {
  const santaAuthorizedOpenapiServicesConfParams = await get(santaAuthorizedOpenapiServicesConfParamsAtom);
  const learningDomain = await get(learningDomainAtom);
  return produce(santaAuthorizedOpenapiServicesConfParams, draft => {
    if (draft.headers == null) throw new Error('Headers must be set');
    draft.headers['riiid-learning-domain'] = learningDomain.id.toString();
  });
});

/**
 * @description Authorization, riiid-learning-domain 헤더를 모두 주입해주는 OpenApi service 호출 객체
 */
const santaOpenapiServicesAtom = atomWithDefault<Promise<OpenAPIServices>>(async get => {
  return createOpenAPIServices(new Configuration(await get(santaOpenapiServicesConfParamsAtom)));
});

export default santaOpenapiServicesAtom;

santaOpenapiServicesConfParamsAtom.debugPrivate = true;
santaOpenapiServicesAtom.debugPrivate = true;
