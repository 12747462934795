import {GrpcServices} from '@app/api/grpc-services';
import {LoggerService} from '@app/api/logger/types';
import * as ProtobufFacade from '@app/facade/protobuf';

export type GrpcLoggerService = LoggerService;

export const createGrpcLoggerService = (
  clientLoggerService: GrpcServices['ClientLoggerService']
): GrpcLoggerService => {
  return {
    async logRpcCall({method, requestStartedAt, timeTakenMs}) {
      const data = {
        method,
        timeTakenMs,
        requestStartedAt: ProtobufFacade.dateToTimestamp(new Date(requestStartedAt)),
      };
      try {
        await clientLoggerService.logClientMetric({
          contextType: 'rpc_call',
          contextDataJson: JSON.stringify(data, null, 2),
        });
      } catch (e) {}
    },
    async logFetch(arg) {
      try {
        await clientLoggerService.logClientMetric({
          contextType: 'fetch_content',
          contextDataJson: JSON.stringify(
            {
              logType: 'fetchContent',
              ...arg,
            },
            null,
            2
          ),
        });
      } catch (e) {}
    },
    async logViewLoad(arg) {
      try {
        await clientLoggerService.logClientMetric({
          contextType: 'view_load',
          contextDataJson: JSON.stringify(
            {
              logType: 'viewLoad',
              ...arg,
            },
            null,
            2
          ),
        });
      } catch (e) {}
    },
  };
};
