import React from 'react';

import {useLoggerService} from '@app/api/logger/context';
import {LoggerService} from '@app/api/logger/types';
import config from '@app/config';

export type ViewLoadStartEvent = {
  type: 'view-load-start';
  time: number;
};

export type ViewLoadingEvent = {
  type: 'view-loading';
};

export type ViewType = 'diagnosis' | 'question' | 'lesson' | 'vocabulary' | 'review' | 'mock_exam';
export type ViewLoadEndEventViewType = ViewType;
export type ViewLoadEndEvent = {
  type: 'view-load-end';
  time: number;
  country: string;
  viewType: ViewLoadEndEventViewType;
};

export type FetchViewType = ViewType;
export type FetchStartEvent = {
  type: 'fetch-start';
  id: string;
  time: number;
  country: string;
};
export type FetchEndEvent = {
  type: 'fetch-end';
  id: string;
  time: number;
};

export const eventFactory = {
  createViewLoadStartEvent(): ViewLoadStartEvent {
    return {type: 'view-load-start', time: Date.now()};
  },
  createViewLoadingEvent(): ViewLoadingEvent {
    return {type: 'view-loading'};
  },
  createViewLoadEndEvent({country, viewType}: {country: string; viewType: ViewLoadEndEventViewType}): ViewLoadEndEvent {
    return {type: 'view-load-end', time: Date.now(), country, viewType};
  },
  createFetchStartEvent({id, country}: {id: string; country: string}): FetchStartEvent {
    return {type: 'fetch-start', id, time: Date.now(), country};
  },
  createFetchEndEvent({id}: {id: string}): FetchEndEvent {
    return {type: 'fetch-end', id, time: Date.now()};
  },
};

export type EventStorageItem =
  | ViewLoadStartEvent
  | ViewLoadingEvent
  | ViewLoadEndEvent
  | FetchStartEvent
  | FetchEndEvent;
export interface IEventStorage {
  pushEvent(item: EventStorageItem): void;
}

const isViewLoadStartEvent = (event: EventStorageItem): event is ViewLoadStartEvent => event.type === 'view-load-start';
const isViewLoadingEvent = (event: EventStorageItem): event is ViewLoadingEvent => event.type === 'view-loading';
const isViewLoadEndEvent = (event: EventStorageItem): event is ViewLoadEndEvent => event.type === 'view-load-end';
const isFetchStartEvent = (event: EventStorageItem): event is FetchStartEvent => event.type === 'fetch-start';

const onViewLoadEndEvent = async (
  viewLoadEndEvent: ViewLoadEndEvent,
  events: EventStorageItem[],
  loggerService: LoggerService
) => {
  let lastViewLoadStartEventIndex = -1;
  let lastViewLoadingEventIndex = -1;
  for (let i = events.length - 2; i >= 0; --i) {
    if (isViewLoadEndEvent(events[i])) break;
    if (lastViewLoadStartEventIndex === -1 && isViewLoadStartEvent(events[i])) {
      lastViewLoadStartEventIndex = i;
      break;
    }
    if (lastViewLoadingEventIndex === -1 && isViewLoadingEvent(events[i])) {
      lastViewLoadingEventIndex = i;
    }
  }

  if (lastViewLoadingEventIndex === -1 || lastViewLoadStartEventIndex === -1) return;
  const viewLoadStartEvent = events[lastViewLoadStartEventIndex] as ViewLoadStartEvent;

  const diff = viewLoadEndEvent.time - viewLoadStartEvent.time;

  return await loggerService.logViewLoad({
    requestStartedAt: viewLoadStartEvent.time,
    timeTakenMs: diff,
    country: viewLoadEndEvent.country,
    type: viewLoadEndEvent.viewType,
  });
};

const onFetchEndEvent = async (
  fetchEndEvent: FetchEndEvent,
  events: EventStorageItem[],
  loggerService: LoggerService
) => {
  const fetchStartEvent = events.find(
    (event): event is FetchStartEvent => isFetchStartEvent(event) && event.id === fetchEndEvent.id
  );
  if (!fetchStartEvent) return;
  const diff = fetchEndEvent.time - fetchStartEvent.time;
  return await loggerService.logFetch({
    requestStartedAt: fetchStartEvent.time,
    timeTakenMs: diff,
    country: fetchStartEvent.country,
  });
};

const SIZE = 500;
const events: EventStorageItem[] = [];
if (config.env === 'development' && typeof window !== 'undefined') {
  (window as any).__events__ = events;
}
export const createEventStorage = (loggerService: LoggerService): IEventStorage => {
  return {
    pushEvent(item) {
      events.push(item);
      if (events.length > SIZE) events.shift();
      switch (item.type) {
        case 'view-load-end':
          return onViewLoadEndEvent(item, events, loggerService);
        case 'fetch-end':
          return onFetchEndEvent(item, events, loggerService);
      }
    },
  };
};

export const EventStorageContext = React.createContext<IEventStorage>({
  pushEvent() {},
});

export const useEventStorage = () => {
  return React.useContext(EventStorageContext);
};

export interface EventStroageProviderProps {
  children?: React.ReactNode;
}

export const EventStorageProvider = ({children}: EventStroageProviderProps) => {
  const loggerService = useLoggerService();
  const eventStorage = React.useMemo(() => createEventStorage(loggerService), [loggerService]);

  return <EventStorageContext.Provider value={eventStorage}>{children}</EventStorageContext.Provider>;
};
