import {ParsedUrlQuery} from 'querystring';

export const getQueryString = (query: ParsedUrlQuery, ignoreArray = true) => {
  return (
    '?' +
    Object.entries(query)
      .flatMap<[string, string], [string, string | string[] | undefined]>(([key, value]) => {
        if (value == null || (ignoreArray && Array.isArray(value))) return [] as [string, string][];
        if (Array.isArray(value)) {
          return value.map(valueItem => {
            return [key, valueItem] as [string, string];
          });
        }
        return [[key, value]] as [string, string][];
      })
      .map(([key, value]) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(value);
      })
      .join('&')
  );
};

export function getQueryValue(query: ParsedUrlQuery, key: string): string | undefined;
export function getQueryValue(query: ParsedUrlQuery, key: string, ignoreArray: true): string | undefined;
export function getQueryValue(query: ParsedUrlQuery, key: string, ignoreArray: false): string | string[] | undefined;
export function getQueryValue(
  query: ParsedUrlQuery,
  key: string,
  ignoreArray?: boolean
): string | string[] | undefined {
  const value = query[key];
  if ((ignoreArray == null || ignoreArray) && Array.isArray(value)) {
    return undefined;
  }
  return value;
}
