import {useAtomValue} from 'jotai';

import {createGrpcLoggerService} from '@app/api/logger/GrpcLogger';
import grpcServicesAtom from '@app/atoms/core/grpc-services';

import {LoggerService} from './types';

export const useLoggerService = (): LoggerService => {
  const {ClientLoggerService} = useAtomValue(grpcServicesAtom);
  return createGrpcLoggerService(ClientLoggerService);
};
